import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import Button from '@components/Content/Button/Button'
import Layout from '@components/Layout/Layout'
import Wysiwyg from '@components/Layout/Wysiwyg'

import * as styles from './404.module.scss'

import FF from '@images/FF/white-off.svg'

const Error404 = () => {
	return (
		<Layout type="full">
			<div className={styles.page}>
				<div className={styles.content}>
					<StaticImage src="../images/404-page.png" alt="" loading="eager" />
					<h1 className={styles.title}>Houston, we have a problem</h1>
					<Wysiwyg className={styles.subtext}>
						<p>
							Error 404. The page you requested could not be found. Don't worry, return to previous page
							or go to home.
						</p>
					</Wysiwyg>
					<Button uri="/" label="Return to homepage" large />
				</div>

				<img src={FF} alt="" className={styles.backgroundImage} />
			</div>
		</Layout>
	)
}

export default Error404
